import { Box, Typography } from '@mui/material';
import { WaitingScreensProvider } from 'features/waiting-screens';

const IndexPage = () => (
  <WaitingScreensProvider>
    <Box
      sx={{
        p: 5,
      }}
    >
      <Typography variant="h1" mb={2}>
        Hello, developer!
      </Typography>
      <Typography>Enter a valid room URL to start your work</Typography>
    </Box>
  </WaitingScreensProvider>
);

export default IndexPage;
