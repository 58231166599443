import { useTranslation } from 'react-i18next';
import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { Typography } from '@mui/material';

const NoMatch = () => {
  const { t } = useTranslation('waiting-screens');

  return (
    <WaitingScreen show>
      <Typography>{t('waiting-screens:room.room_not_found')}</Typography>
    </WaitingScreen>
  );
};

export default NoMatch;
