import { getState } from 'store/store';
import { selectHDVideoSettings } from 'features/streaming/streamingSlice';

const resolutions: Record<number, number> = {
  180: 320,
  360: 640,
  540: 960,
  720: 1280,
  1080: 1920,
};

const aspectRatio = 1.77778;

export const getDefaultVideoConstraints = () => {
  const hdVideoSettings = selectHDVideoSettings(getState());

  console.log('hdVideoSettings', hdVideoSettings);

  const videoHeight = hdVideoSettings.resolution;
  const videoWidth = resolutions[videoHeight] || Math.round(videoHeight * aspectRatio);

  return {
    width: { ideal: videoWidth },
    height: { ideal: videoHeight },
    advanced: [
      { frameRate: { min: 24 } },
      { width: { min: videoWidth } },
      { height: { min: videoHeight } },
      { frameRate: { max: 24 } },
      { width: { max: videoWidth } },
      { height: { max: videoHeight } },
      { aspectRatio: { exact: aspectRatio } },
    ],
  };
};
