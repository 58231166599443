import { call, put, select } from 'redux-saga/effects';
import { downloadFile } from 'utils/http/downloadFile';
import { AxiosResponse } from 'axios';
import { selectRoomId } from 'features/room/roomSlice';
import { exportChatService } from 'features/chat/services';
import * as Sentry from '@sentry/react';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { exportChatFulfilled, exportChatRejected } from 'features/chat/chatSlice';

export function* exportChatSaga() {
  try {
    const roomId: string = yield select(selectRoomId);

    const response: AxiosResponse<Blob> = yield call(exportChatService, roomId);
    const contentDisposition = response.headers['content-disposition'];

    yield call(downloadFile, response.data, contentDisposition);

    yield put(exportChatFulfilled());
  } catch (error) {
    yield put(exportChatRejected());
    yield call(notification, i18n.t('notifications:chat.chat_download_error'));
    Sentry.captureException(error);
  }
}
