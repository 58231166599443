import { call, select, put } from 'redux-saga/effects';
import { PermissionTypes } from 'features/permissions/types';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { whiteboardClosed, whiteboardOpened } from 'features/layout/features/content/actions';
import { eventBus } from 'utils/eventBus';

export function* onToggleWhiteboardSaga(show?: boolean) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'whiteboard');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Whiteboard disabled. You’ll need to edit this room’s properties to use whiteboard in this room',
      'whiteboard'
    );

    return;
  }

  const canToggleWhiteboard: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.editWhiteboard
  );

  if (!canToggleWhiteboard) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to control whiteboard',
      'whiteboard'
    );

    return;
  }

  const whiteboardOpen: boolean = yield select(selectWhiteboardOpen);

  show = typeof show === 'undefined' ? !whiteboardOpen : show;

  yield put(show ? whiteboardOpened() : whiteboardClosed());
}
