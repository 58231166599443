import React, { useEffect } from 'react';
import webrtcAdapter from 'webrtc-adapter';
import { useVideoInputField } from 'hooks/useVideoInputField';
import VideoRenderer from 'components/VideoRenderer';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { TabPanelInner } from 'components/Tabs';
import DeviceSelect from 'components/DeviceSelect';
import { useTranslation } from 'react-i18next';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RoomSettingsFormValues } from 'features/room/settings/RoomSettings';
import {
  hdStreamToggled,
  preserveAspectRatioToggled,
  selectHDVideoSettings,
  selectLocalVideoBroadcasting,
  selectLocalVideoEnabled,
  selectPreserveAspectRatio,
} from 'features/streaming/streamingSlice';
import { useTemporaryMedia } from 'hooks/useTemporaryMedia';
import {
  disableDeviceAutoChangeAlert,
  selectDeviceAutoChangeStatus,
} from 'features/user-media/userMediaSlice';
import { Box } from '@mui/material';
import { streamingDeviceChanged } from 'features/streaming/actions';
import { useMediaDevices } from 'hooks/useMediaDevices';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';
import { getDefaultVideoConstraints } from 'features/user-media/utils/getDefaultVideoConstraints';

export type VideoTabProps = {
  control: Control<RoomSettingsFormValues>;
  setValue: UseFormSetValue<RoomSettingsFormValues>;
};

const VideoTab = ({ control, setValue }: VideoTabProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('settings');

  const isVideoBroadcasting = useAppSelector(selectLocalVideoBroadcasting);
  const isVideoEnabled = useAppSelector(selectLocalVideoEnabled);
  const deviceAutoChange = useAppSelector(selectDeviceAutoChangeStatus);

  const hdVideoSettings = useAppSelector(selectHDVideoSettings);

  const preserveAspectRatio = useAppSelector(selectPreserveAspectRatio);

  const { camPermissions, activeMediaDevices, persistDevices } = useMediaDevices();

  const { videoInputOptions, videoInputPlaceholder } = useVideoInputField();
  const { videoStream } = usePublishingFeed('media');

  const {
    loading: videoLoading,
    requestTemporaryMedia,
    mediaStream: tempVideoStream,
    cleanupTemporaryMedia,
  } = useTemporaryMedia();

  useEffect(() => {
    if (!videoStream) {
      requestTemporaryMedia({
        video: {
          ...getDefaultVideoConstraints(),
          deviceId: {
            exact: activeMediaDevices.videoinput,
          },
        },
      });
    }

    return () => {
      cleanupTemporaryMedia();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const requestVideo = async () => {
      await requestTemporaryMedia({
        video: {
          deviceId: {
            ...getDefaultVideoConstraints(),
            exact: activeMediaDevices.videoinput,
          },
        },
      });
    };

    if (deviceAutoChange.videoinput) {
      setValue('videoinput', activeMediaDevices.videoinput || '', { shouldDirty: true });
      dispatch(disableDeviceAutoChangeAlert('videoinput'));

      if (!isVideoEnabled) {
        requestVideo();
      }
    }
  }, [
    activeMediaDevices.videoinput,
    isVideoEnabled,
    deviceAutoChange.videoinput,
    requestTemporaryMedia,
    setValue,
    dispatch,
  ]);

  const handleCameraChange = async (deviceId: string) => {
    cleanupTemporaryMedia();

    persistDevices(deviceId, 'videoinput');

    if (!isVideoBroadcasting) {
      await requestTemporaryMedia({
        video: {
          ...getDefaultVideoConstraints(),
          deviceId: {
            exact: deviceId,
          },
        },
      });
    }

    dispatch(
      streamingDeviceChanged({
        id: deviceId,
        kind: 'videoinput',
      })
    );
  };

  const handleHDStreamChange = () => {
    dispatch(hdStreamToggled());
  };

  const handlePreserveAspectRatioChange = () => {
    dispatch(preserveAspectRatioToggled());
  };

  return (
    <TabPanelInner>
      <div>
        <VideoRenderer
          mediaStream={videoStream || tempVideoStream}
          sx={{ mb: 8 }}
          loading={videoLoading}
        />
        <DeviceSelect
          id="settings-video-select"
          name="videoinput"
          control={control}
          icon="camera"
          onChange={handleCameraChange}
          options={videoInputOptions}
          disabled={camPermissions !== 'granted'}
          placeholder={videoInputPlaceholder}
          ariaLabel={t('device_dropdowns.videoinput.aria_label')}
        />
      </div>
      <Box sx={{ mt: 8 }}>
        <SettingControl
          label={t('video.preserve_aspect_ratio')}
          alignItems="flex-start"
          control={
            <Switch
              onChange={handlePreserveAspectRatioChange}
              checked={preserveAspectRatio}
              inputProps={{
                'aria-label': preserveAspectRatio
                  ? t('video.preserve_aspec_ratio_disable_aria_label')
                  : t('video.preserve_aspec_ratio_enable_aria_label'),
              }}
            />
          }
        />
      </Box>
      {webrtcAdapter.browserDetails.browser !== 'firefox' ? (
        <Box sx={{ mt: 8 }}>
          <SettingControl
            label={t('video.hd_stream_label')}
            helperText={t('video.hd_stream_warning')}
            alignItems="flex-start"
            control={
              <Switch
                onChange={handleHDStreamChange}
                checked={hdVideoSettings.enabled}
                inputProps={{
                  'aria-label': hdVideoSettings.enabled
                    ? t('video.hd_stream_disable_aria_label')
                    : t('video.hd_stream_enable_aria_label'),
                }}
              />
            }
          />
        </Box>
      ) : null}
    </TabPanelInner>
  );
};

export default VideoTab;
