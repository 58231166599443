import webrtcAdapter from 'webrtc-adapter';
import {
  ActiveMediaDevices,
  EnumeratedMediaDevices,
  MediaDevicesPermissions,
  PermissionStatus,
} from 'features/user-media/types';
import { getRelatedDevice } from 'features/user-media/utils/getRelatedDevice';
import { getDefaultPromptedDevice } from 'features/user-media/utils/getDefaultPromptedDevice';
import { userAgentDetails } from 'utils/userAgentDetails';
import { getDefaultVideoConstraints } from 'features/user-media/utils/getDefaultVideoConstraints';

export type DefaultDevice = string | undefined;

export async function enumerateDevices() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const systemInfo = userAgentDetails();

  const isAndroidWebview = systemInfo.browser.name === 'Chrome WebView';

  const result: EnumeratedMediaDevices = {
    audioinput: [],
    videoinput: [],
    audiooutput: [],
  };

  for (const device of devices) {
    const deviceKind = device.kind;

    const skipAndroidDefaultMic =
      isAndroidWebview &&
      device.deviceId === 'default' &&
      deviceKind === 'audioinput' &&
      !device.label;

    if (!skipAndroidDefaultMic) {
      result[deviceKind].push(device.toJSON());
    }
  }

  return result;
}

export function stopStreamTracks(mediaStream: MediaStream | null) {
  if (!mediaStream) {
    return;
  }

  const tracks = mediaStream.getTracks();
  tracks.forEach((mst) => {
    mst.stop();
  });
}

export const isMediaDeviceAvailable = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind
) => !!mediaDevices[kind].length;

export const isDeviceGivenPermission = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind
) => !!mediaDevices[kind][0]?.label;

export const getDevicePermissions = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind,
  initialSetup?: boolean
): PermissionStatus => {
  const isAvailable = isMediaDeviceAvailable(mediaDevices, kind);

  if (!isAvailable) {
    return 'not-found';
  }

  if (webrtcAdapter.browserDetails.browser === 'safari') {
    if (initialSetup) {
      return 'prompt';
    }
  }

  // if user disabled device or denied permissions, device 'label' will be blank
  return isDeviceGivenPermission(mediaDevices, kind) ? 'granted' : 'denied';
};

export const updateMediaDevicesPermissions = (
  mediaDevices: EnumeratedMediaDevices,
  initialSetup: boolean
): MediaDevicesPermissions => {
  const permissions: MediaDevicesPermissions = {};

  for (const kind of Object.keys(mediaDevices) as MediaDeviceKind[]) {
    permissions[kind] = getDevicePermissions(mediaDevices, kind, initialSetup);
  }

  return permissions;
};

export const getDefaultMediaDevice = (
  mediaDevices: EnumeratedMediaDevices,
  kind: MediaDeviceKind
): DefaultDevice => {
  const isAvailable = isMediaDeviceAvailable(mediaDevices, kind);

  if (!(isAvailable && isDeviceGivenPermission(mediaDevices, kind))) {
    return undefined;
  }

  const targetDevice = mediaDevices[kind].find((device) => device.deviceId);
  if (!targetDevice) {
    return undefined;
  }

  const defaultDevice = getRelatedDevice(mediaDevices[kind], targetDevice);

  return defaultDevice.deviceId;
};

export const prepareActiveDevices = (mediaDevices: EnumeratedMediaDevices): ActiveMediaDevices => {
  const activeDevices: ActiveMediaDevices = {};
  const keys = Object.keys(mediaDevices) as MediaDeviceKind[];

  for (const kind of keys) {
    activeDevices[kind] = getDefaultPromptedDevice(mediaDevices, kind);
  }

  return activeDevices;
};

// Determines the requested user media
export const getBaseMediaConstraints = (mediaDevices: EnumeratedMediaDevices) => ({
  audio: isMediaDeviceAvailable(mediaDevices, 'audioinput'),
  video: isMediaDeviceAvailable(mediaDevices, 'videoinput'),
});

export const getDefaultActiveDevices = (mediaDevices: EnumeratedMediaDevices) => ({
  audioinput: getDefaultPromptedDevice(mediaDevices, 'audioinput'),
  videoinput: getDefaultPromptedDevice(mediaDevices, 'videoinput'),
  audiooutput: getDefaultPromptedDevice(mediaDevices, 'audiooutput'),
});

export const getDevicePromptConstraints = (mediaDevices: EnumeratedMediaDevices) => ({
  audio: isMediaDeviceAvailable(mediaDevices, 'audioinput')
    ? {
        deviceId: {
          exact: getDefaultPromptedDevice(mediaDevices, 'audioinput'),
        },
      }
    : false,
  video: isMediaDeviceAvailable(mediaDevices, 'videoinput')
    ? {
        ...getDefaultVideoConstraints(),
        deviceId: {
          exact: getDefaultPromptedDevice(mediaDevices, 'videoinput'),
        },
      }
    : false,
});
