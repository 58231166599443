import { gridPanelClosed } from 'features/layout/features/content/contentSlice';
import { call, put } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';
import { eventBus } from 'utils/eventBus';

export function* onWhiteboardClosedSaga() {
  yield put(gridPanelClosed());

  yield call(board.stop);

  yield call(eventBus.sendMessage, 'whiteboardClosed');
}
